import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/index.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/index',
        component: () => import('@/views/index/Index'),
        name: 'index',
        meta: { index: 0 }
    },
    {
        path: '/tutorial',
        name: 'tutorial',
        component: Layout,
        children: [
            { path: '/tutorial',name:'tutorial', component: () => import('@/views/tutorial/Index'), meta: { index: 1 } }
        ],
    },
    {
        path: '/order',
        name: 'order',
        component: Layout,
        children: [
            { path: '/order',name:'order',component: () => import('@/views/order/Index'), meta: { index: 2 } }
        ],
    },
    {
        path: '/agent',
        name: 'agent',
        component: Layout,
        children: [
            { path: '/agent',name:'agent',component: () => import('@/views/agent/Index'), meta: { index: 3 } }
        ],
    },
    {
        path: '/serviceIndex',
        component: () => import('@/views/ServiceIndex'),
        name: 'ServiceIndex',
        meta: { index: 4 }
    },
    { path: '/', redirect: '/index' },
    {
        path: '/fastFish',
        name: 'fastFish',
        component: Layout,
        children: [
            { path: '/fastFish',name:'fastFish', component: () => import('@/views/fastFish/Index'), meta: { index: 5 } }
        ],
    },
    {
        path: '/fastFishDetail',
        name: 'articleDetail',
        component: Layout,
        children: [
            { path: '/fastFishDetail',name:'detail', component: () => import('@/views/fastFish/article/Detail'), meta: { index: 5 } }
        ],
    },
    {
        path: '/fastFishMore',
        name: 'articleMore',
        component: Layout,
        children: [
            { path: '/fastFishMore',name:'more', component: () => import('@/views/fastFish/article/More'), meta: { index: 5 } }
        ],
    },
    {
        path: '/courseMore',
        name: 'courseMore',
        component: Layout,
        children: [
            { path: '/courseMore',name:'more', component: () => import('@/views/fastFish/course/More'), meta: { index: 5 } }
        ],
    },


]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
