<template>
  <div id="app">
    <router-view class="pages"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  mounted() {
    this.getFoot()
  },
  methods: {
    async getFoot(){
      let res = await this.$http({
        url: `home/index/foot`,
        method: "post",
        data: {
        }
      });
      const {code, msg , data} = res;
      if (code === 200) {
        sessionStorage.setItem('foot',JSON.stringify(data))
      }
    },
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.text{
  font-size: 14px;
}
.test_box{
  width: 147px;
  height: 90px;
  background: #f2f2f2;
}
</style>
