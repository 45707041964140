<template>
  <div>
    <div class="p-center">
      <div class="footBox">
        <div class="footCon">
          <div class="foot_about">关于{{ footData.webName }}</div>
          <div class="foot_center">
            <div class="foot_center_left">
<!--              <div @click="$router.push('/order')">服务订购</div>-->
<!--              <div @click="$router.push('/tutorial')">教学视频</div>-->
              <div @click="toLogin">用户登录</div>
            </div>
            <div class="foot_center_center">
              <div class="foot_center_center_phone">{{ footData.tel }}</div>
              <div class="foot_center_center_txt">咨询热线（服务时间：09:00-18:00  工作日）</div>
            </div>
            <div class="foot_center_right">
              <div class="foot_center_right_title">客服微信</div>
              <div class="foot_center_right_img">
                <img :src="$baseImageUrl + footData.wechat" alt="">
              </div>
            </div>
<!--            <div class="foot_center_right" style="margin-left: 20px">-->
<!--              <div class="foot_center_right_title">微信公众号</div>-->
<!--              <div class="foot_center_right_img">-->
<!--                <img :src="$baseImageUrl + footData.wechat_gzh" alt="">-->
<!--              </div>-->
<!--            </div>-->
          </div>
          <div style="margin-top: 50px;cursor: pointer" @click="toHref">
            <div class="foot_bottom">{{ footData.bah }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="m-center">
      <div class="footBox">
        <div class="foot_about">关于{{ footData.webName }}</div>
        <div class="foot_center">
          <div class="foot_center_left">
            <div class="foot_center_left_top">
<!--              <div @click="$router.push('/order')">服务订购</div>-->
<!--              <div @click="$router.push('/tutorial')">教学视频</div>-->
              <div @click="toLogin">用户登录</div>
            </div>
            <div class="foot_center_left_center">{{ footData.tel }}</div>
            <div class="foot_center_left_bottom">咨询热线（服务时间：09:00-18:00  工作日）</div>
          </div>
          <div class="foot_center_right">
            <div class="foot_center_right_img">
              <img :src="$baseImageUrl + footData.wechat" alt="">
            </div>
            <div>客服微信</div>
          </div>
        </div>
        <div class="foot_bottom" @click="toHref">
          <div>{{ footData.bah }}</div>
<!--          <div>Copyright © 2016-2021 xxxx科技有限公司 All Rights Reserved.</div>-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      footData: {}
    }
  },
  mounted() {
    this.getFoot()
  },
  methods: {
    toLogin(){
      window.open(this.footData.login_address)
    },
    async getFoot(){
      let res = await this.$http({
        url: `home/index/foot`,
        method: "post",
        data: {
        }
      });
      const {code, msg , data} = res;
      if (code === 200) {
        this.footData = data
        sessionStorage.setItem('foot',JSON.stringify(data))
      }
    },
    toHref(){
      window.open('https://beian.miit.gov.cn/')
    },
  }
}
</script>

<style scoped lang="less">
a{text-decoration:none}
.p-center{
  .footBox{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 326px;
    //background: #00062B;
    background: linear-gradient(90deg, #EC2F46 0%, #C30D23 100%);
    .footCon{
      width: 50%;
      text-align: left;
      .foot_about{
        font-size: 26px;
        font-weight: bold;
        color: #F1F2FF;
      }
      .foot_center{
        display: flex;
        margin-top: 20px;
        .foot_center_left{
          width: 20%;
          font-size: 20px;
          font-family: Microsoft YaHei-Light, Microsoft YaHei;
          font-weight: 300;
          color: #F1F2FF;
          line-height: 45px;
        }
        .foot_center_center{
          text-align: center;
          width: 60%;
          margin: 20px 5% 0;
          .foot_center_center_phone{
            font-size: 36px;
            //font-family: DIN-Bold, DIN;
            font-weight: bold;
            color: #F1F2FF;
          }
          .foot_center_center_txt{
            font-size: 16px;
            font-weight: 300;
            color: #F1F2FF;
            margin-top: 10px;
          }
        }
        .foot_center_right{
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          .foot_center_right_title{
            font-size: 16px;
            font-weight: 300;
            color: #F1F2FF;
          }
          .foot_center_right_img{
            width: 144px;
            height: 144px;
            background: #999999;
            margin-top: 10px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .foot_bottom{
        font-size: 14px;
        font-weight: 300;
        color: #F1F2FF;
      }
    }

  }
}
.m-center{
  .footBox{
    background: linear-gradient(90deg, #EC2F46 0%, #C30D23 100%);
    padding: 30px 20px 10px;
    text-align: left;
    .foot_about{
      font-size: 14px;
      font-weight: 400;
      color: #F1F2FF;
    }
    .foot_center{
      display: flex;
      justify-content: space-between;
      .foot_center_left{
        .foot_center_left_top{
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(229, 229, 229, 0.1);
          margin-top: 10px;
          padding-top: 3px;
          padding-bottom:10px;
          font-size: 10px;
          font-weight: 300;
          color: #F1F2FF;
        }
        .foot_center_left_center{
          font-size: 24px;
          font-weight: bold;
          margin-top: 10px;
          color: #F1F2FF;
        }
        .foot_center_left_bottom{
          font-size: 10px;
          font-weight: 300;
          color: #F1F2FF;
          margin-top: 4px;
        }
      }
      .foot_center_right{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 10px;
        font-weight: 300;
        color: #F1F2FF;
        .foot_center_right_img{
          width: 75px;
          height: 75px;
          background: #999999;
          margin-bottom: 10px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }

    }
    .foot_bottom{
      text-align: center;
      font-size: 8px;
      font-weight: 300;
      color: #F1F2FF;
      margin-top: 20px;
    }
  }
}


</style>
